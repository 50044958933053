import React from 'react';
import { graphql } from 'gatsby';
import { SEO } from '../../components/SEO';
import { Layout } from '../../layouts/Layout';

const MarkdownPostTemplate = ({
  data, // this prop will be injected by the GraphQL query below.
}) => {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  return (
    <div className="w-full mx-auto my-8 max-w-[960px]">
      <div className="blog-post">
        <h1>{frontmatter.title}</h1>
        <h2>{frontmatter.date}</h2>
        <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  );
};

export default function ArchivePostPage({
  data, // this prop will be injected by the GraphQL query below.
}) {
  return (
    <Layout>
      <SEO title={null} titleTemplate="" />
      <MarkdownPostTemplate data={data} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`;
